import { Button, Grid, Typography } from "@material-ui/core"
import { Link } from "gatsby"
import React from "react"
import Layout from "../components/Layout"

const notFoundPage = () => {
  const pageMeta = {
    title: "صفحة غير موجودة",
    description: "صفحة غير موجودة",
    keywords: ["صفحة", "غير موجودة"],
  }
  return (
    <Layout pageMeta={pageMeta}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        spacing={2}
        style={{ height: "60.6vh" }}
      >
        <Grid item>
          <Typography style={{ fontSize: "4rem" }} paragraph>
            <span style={{ color: "#C30" }}>404</span> | هذه الصفحة غير موجودة
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" component={Link} to="/">
            الصفحة الرئيسية
          </Button>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default notFoundPage
